import ProTable, { createIntl, IntlProvider } from "@ant-design/pro-table"
import { TableEmptyState } from "@components/TableEmptyState"
import { ConfigProvider } from "antd"
import ptBR from "antd/es/locale/pt_BR"
import React from "react"

const ptBrLocale = {
  tableForm: {
    search: "Buscar",
    reset: "Limpar",
    submit: "Submit",
    collapsed: "Ver mais filtros",
    expand: "Ver menos",
    inputPlaceholder: "Informe o filtro",
    selectPlaceholder: "Selecione o filtro",
  },
  alert: {
    clear: "Limpar",
    selected: "Selecionado",
    item: "Item",
  },
  tableToolBar: {
    leftPin: "Fixo a esquerda",
    rightPin: "Fixo a direita",
    noPin: "Solto",
    leftFixedTitle: "Título fixo a esquerda",
    rightFixedTitle: "Título fixo a direita",
    noFixedTitle: "Não fixado",
    reset: "Resetar",
    columnDisplay: "Exibição de coluna",
    columnSetting: "Configurações",
    fullScreen: "Tela cheia",
    exitFullScreen: "Sair da tela cheia",
    reload: "Atualizar",
    density: "Densidade",
    densityDefault: "Padrão",
    densityLarger: "Maior",
    densityMiddle: "Normal",
    densitySmall: "Compacto",
  },
}

const ptBRIntl = createIntl("pt_BR", ptBrLocale)

function CustomProTable({
  size = "small",
  total,
  scrollX = "true",
  setting = false,
  ...rest
}) {
  const tableOptions = {
    search: true,
    bordered: true,
    rowKey: (record) => record.id,
    pagination: {
      total,
      defaultPageSize: 10,
      showSizeChanger: true,
      showTotal: (total) => {
        if (total > 0) {
          return `Total ${total} ${total > 1 ? "itens" : "item"}`
        }

        return null
      },
    },
    options: {
      reload: false,
      fullScreen: false,
      setting,
      density: false,
    },
    showSizeChanger: true,
    locale: {
      emptyText: <TableEmptyState />,
    },
    scroll: {
      x: scrollX,
    },
    ...rest,
  }

  return (
    <ConfigProvider locale={ptBR}>
      <IntlProvider value={{ intl: ptBRIntl }}>
        <ProTable {...tableOptions} size={size} />
      </IntlProvider>
    </ConfigProvider>
  )
}

export default CustomProTable
